.knowledge-test-box {

    &.knowledge-test-warning {
        background-color: $danger-bg !important;
    }

    .test-title {
        margin-bottom: 10px;
    }

    .box-body {
        margin-top: 19px;
    }

    .previous-attempt {
        font-weight: $font-weight-bold-default;
        &.attempt-running { color: $brand-6; }
        &.attempt-success { color: $brand-9; }
        &.attempt-fail { color: #b32d0d; }
    }

    .label {
        text-transform: uppercase;
        padding: .5em .6em .3em;

        &.label-danger {
            background-color: #b32d0d;
        }
    }

    .test-attempts-count {
        font-weight: $font-weight-bold-default;
        padding-top: 0px;
    }

    .button-wrapper {
        min-height: 46px;
    }
}
// Login page is the same for every area
#login {
    .part-1 {
        padding: 60px 20px 0px 20px;
        background-color: $brand-2;
        text-align: center;
        white-space: pre-wrap;
        word-wrap: break-word;

        h1, h2 {
            color: $white;
        }

        h2 {
            margin-top: 20px;
        }

        .illustration {
            min-height: 610px;
            background-image: url("/frontend/assets/images/ihrdinove/login_illustration.png");
            background-position: bottom;
            background-repeat: no-repeat;
            background-size: auto;
        }
    }

    .part-2 {
        padding: 50px 10px;
        background-color: $white;

        h2 {
            text-align: center;
            color: $brand-4;
            padding-bottom: 70px;
        }

        .subheading {
            display: none;
        }

        .thumbnail {
            h3 {
                text-align: center;
                color: $brand-5;
                text-transform: uppercase;
                padding: 10px 0 20px 0px;
                min-height: 106px;
                @include respond-to(screen-below-md) { min-height: 0; }
            }

            p {
                text-align: center;
            }

            .img {
                min-height: 250px;
            }
        }
    }

    .part-3 {
        padding: 60px 20px;
        background-color: $brand-3;

        h2 {
            color: $brand-6;
            text-align: center;
            margin-bottom: 15px;
            text-transform: uppercase;
        }

        h3 {
            color: $white;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-bottom: 40px;
            width: 50%;
            @include respond-to(screen-below-md) { width: 100%; }
        }

        .panel {
            padding: 30px 15px;
            @include respond-to(very-narrow-screen) { padding: 20px 5px; }

            a {
                @include font-styles($brand-5, $font-size-base-default, $font-weight-medium-default);
                text-decoration: underline;
                cursor: pointer;
            }

            .forgotten-pass-link-wrapper {
                width: 100%;
                text-align: left;
                @include respond-to(very-narrow-screen) { text-align: center;}
            }

            .submit-btn-wrapper {
                margin-top: 20px;
                text-align: right;
                @include respond-to(very-narrow-screen) { text-align: center;}
            }

            .panel-footer {
                padding-top: 30px;
                border-top: 1px solid #d2e1ea;
                text-align: center;

                .btn {
                    min-width: 165px;
                }
            }
        }
    }
}

#knowledge-test {
    .part-1 {
        padding: 70px 35px;

        .container {
            background-color: #ffffff;
            padding: 40px 75px;
        }

        .test-loading {
            min-height: 750px;
        }
    }

    .attempt-number, .remaining-time, .correct-answers, .result-points {
        font-weight: $font-weight-bold-default;
    }

    #test-video {
        .video-title {
            margin-top: 20px;
        }
        .video-desc {
            margin-bottom: 20px;
        }
        .video-row {
            padding: 10px 0;
        }

        .btn-row {
            text-align: center;
            margin-top: 20px;
        }

        // fixing weird IE11 flex error - not showing controls when video is not watched. Now all the
        // controls are present, just hide the progressbar when the video is not watched yet
        .video-not-watched .plyr__progress {
            display: none !important;
            pointer-events: none !important;
        }
    }

    #test-items {
        .progress {
            margin-top: 30px;
        }

        .item-btn-row {
            margin-top: 20px;

            .btn {
                padding: 13px 20px;
                min-width: 190px;
            }
        }

        .item-desc {
            background-color: #f1f1f1;
            padding: 20px 30px;
            margin-top: 30px;
            h3 { color: #1c1c1c; }

            .img {
                margin-top: 20px;
                max-height: 400px;
            }
        }

        .item-answer {
            margin-top: 30px;

            textarea {
                border-color: #e5e5e5;
                padding: 25px 30px;
            }
            textarea:focus,
            textarea.answered {
                border-color: $brand-1;
            }

            &.choice-answer {
                .btn-success { margin-top: 30px; }
            }
        }

        .test-checkbox {
            border: 1px solid #e5e5e5;
            margin: 10px 0;
            padding: 22px 35px 17px 35px;
            min-height: 100px;

            &:hover {
                background-color: #f3f3f3;
            }

            .checkbox-col {
                padding-top: 5px;
                padding-right: 0;
            }

            .tag-col {
                padding-top: 0;
                padding-left: 15px;
            };

            .regular-checkbox { display: none; }

            label {
                background-color: $white;
                border: 1px solid #e5e5e5;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                margin-bottom: 0;
                display: inline-block;
                cursor: pointer;

                @include font-styles($brand-1, $font-size-large-default, $font-weight-light-default, 1);

                padding: 10px 10px;
                position: relative;
                text-align: center;
            }

            &.checked {
                border: 1px solid $brand-1;
                label {
                    background-color: $brand-1;
                    @include font-styles($white, $font-size-large-default, $font-weight-light-default, 1);
                }
            }

            .tag {
                @include font-styles($text-color-default, $font-size-base-default, $font-weight-light-default, 1);
                display: inline;
            }
        }
    }

    #test-pagination {
        margin-top: 35px;

        @include flexbox();
        @include flex-direction(row);

        .transport-left { margin-right: 30px; }
        .transport-right { margin-left: 30px; }

        .pagination-center {
            display: inline-block;
            @include flex(1);
            @include flexbox();
            @include flex-direction(row);
            @include align-items(center);
            @include justify-content(center);
            @include flex-wrap(wrap);
        }

        .page-item {
            display: inline-block;
            width: 40px;
            margin: 5px;

            .page-link {
                cursor: pointer;
                text-decoration: none;

                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                border: none;

                display: block;
                width: 40px;
                padding: 4px 0px;
                text-align: center;

                color: $white;
                background-color: $brand-1;
                font-size: $font-size-base + 2px;
                font-weight: $font-weight-bold-default;

                &:hover {
                    background-color: darken($brand-1, 10%);
                }
            }

            &.answered .page-link {
                background-color: $brand-9;
                &:hover {
                    background-color: darken($brand-9, 10%);
                }
            }

            &.active .page-link {
                box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.25);
            }
        }
    }

    #test-result {
        $correct-color: #60bb46;
        $incorrect-color: #bb2f18;
        $no-answer-color: #f0ad4e;
        .text-correct { color: $correct-color; }
        .text-incorrect { color: $incorrect-color; }

        .result-title {
            margin-top: 17px;
            margin-bottom: 10px;
        }

        .item-result {
            margin: 20px 0 10px 0;
            padding: 20px 30px;

            &.correct-answer {
                border: 1px solid $correct-color;
                .item-number, .item-points { color: $correct-color; }
            }

            &.incorrect-answer {
                border: 1px solid $incorrect-color;
                .item-number, .user-answer, .item-points { color: $incorrect-color; }
            }

            &.no-answer {
                border: 1px solid $no-answer-color;
                .item-number, .item-points { color: $no-answer-color; }
            }

            &.manual-answer {
                border: 1px solid $gray;
            }

            .result-label {
                text-transform: uppercase;
                padding: .5em .6em .3em;
                margin-left: 7px;

                &.label-danger { background-color: $incorrect-color; }
            }

            .item-points { font-weight: $font-weight-bold-default; }

            .item-name {
                color: $text-color-default;
                margin-top: 7px;
            }

            .user-answer { margin-top: 5px; }

            .answer-title {
                font-weight: $font-weight-bold-default;
            }

            .answer-list {
                padding-left: 0;
                li {
                    list-style-type: none;
                    border: 1px solid #e5e5e5;
                    padding: 10px 10px 10px 15px;
                    margin: 10px 0;
                }
            }
        }

        .remaining-attempts,
        .manual-info,
        .manual-item-info { font-weight: $font-weight-bold-default; }

        .remaining-attempts { margin-top: 20px; }

        .btn-row {
            margin-top: 20px;
            text-align: center;
            .btn {
                min-width: 194px;
                margin: 5px;
            }
        }
    }
}
// ----------------
//  Navbar
// ---------------
.navbar {
    @include box-shadow(0px 2px 0px 0px $gray-shadow);
    border-width: 0px;

    .navbar-header {
        .navbar-brand {
            padding-top: 28px;
            .logo-navbar {
                height: 19px;
            }
        }
        @include respond-to(screen-sm) { display: none }
    }

    .navbar-nav {
        @include respond-to(navbar-collapsed) {
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    .navbar-nav > li {
        font-size: $font-size-base-default;
        font-weight: $font-weight-regular-default;

        &.active {
            //color: $brand-1;
        }

        > a {
            padding-left: 20px;
            padding-right: 20px;
            @include respond-to(screen-sm) {
                padding-left: 8px;
                padding-right: 8px;
            }

            @include respond-to(screen-md) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &.user-info {
            padding-top: 20px;
            padding-bottom: 20px;

            @include respond-to(navbar-collapsed) {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .user-info-content-wrapper {
                padding-left: 10px;
                padding-right: 10px;
                max-width: 275px; // empirically found to prevent two lines in navbar
                border-right: 1px solid #20252b;
                @include respond-to(navbar-collapsed) {
                    border: none;
                    max-width: none;
                }
            }

            a, a:focus, a:hover {
                text-decoration: none !important;
            }

            a {
                white-space: nowrap;
                overflow: hidden;

                color: $text-color-default;
                padding: 0 10px;
                font-weight: $font-weight-light-default;
                font-size: $font-size-small-default;
                line-height: 1.3;
                position: relative;
                display: block;
                text-decoration: none;
            }

            .user-points {
                color: $brand-1;
                font-weight: $font-weight-medium-default;
                text-transform: uppercase;
                cursor: pointer;
            }

            &.active{
                background-color: $navbar-default-link-active-bg;
            }
        }

        &.logout {
            a {
                padding-right: 0;
                font-size: $font-size-small-default;
                font-weight: 400;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
            }

            i {
                color: #bc3019;
                margin-right: 3px;
            }
        }


        .navbar-btn {
            margin-top: 17px;
            margin-bottom: 17px;
            padding-left: 15px;
            padding-right: 15px;

            @include respond-to(navbar-collapsed) {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .navbar-toggle, .navbar-toggle:hover, .navbar-toggle:focus {
        border-radius: 0;
    }

    // the main login page before user is logged in
    &.guest-navbar {
        @include box-shadow(none);
        background-color: $brand-2;

        .navbar-header {
            .navbar-brand {
                .logo-navbar {
                    height: 27px;
                }
            }
        }

        .navbar-nav > li > a,
        .navbar-nav > .active > a,
        .navbar-nav > .active > a:hover,
        .navbar-nav > .active > a:focus {
            color: $white;
            font-weight: $font-weight-medium-default;
            background-color: $brand-2;
            cursor: pointer;
        }

        .navbar-toggle, .navbar-toggle:hover, .navbar-toggle:focus {
            background-color: $white;
            border-radius: 0;
        }
    }
}

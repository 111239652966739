footer {
    background: #262424;
    @include font-styles(#e2e2e2, $font-size-small-default, $font-weight-regular-default);
    border: none;
    padding: 25px 0 25px 0;

    @include flexbox();
    align-items: center;
    @include respond-to(screen-below-sm) {
        display: block;
    }

    .row {
        @include flexbox();
        align-items: center;
        @include respond-to(screen-below-sm) {
            display: block;
        }

        position: relative;

        // innogy footer logo
        .img {
            position: absolute;
            margin-top: -27px;
            top: 50%;
            left: 0;
            @include respond-to(screen-below-sm) {
                left: 20px;
            }
            @include respond-to(very-narrow-screen) {
                display: none;
            }
        }
    }

    .left-column {
        text-align: center;
        padding-left: 40px;
    }

    .middle-column {
        text-align: center;
    }

    .right-column {
        text-align: right;
        @include respond-to(screen-sm) {
            text-align: center
        }
        @include respond-to(screen-below-sm) {
            text-align: center
        }
    }
}

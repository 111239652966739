// These are default colors
$brand-1: #00aaad;
$brand-2: #5e0151;
$brand-3: #ba0646;
$brand-4: #c62b91;
$brand-5: #00b3e7;
$brand-6: #ffc20e;
$brand-7: #f15922;
$brand-8: #696261;
$brand-9: #60bb46;

$text-color-default: #231f20;
$text-color: $text-color-default; // bootstrap var. Cannot be really overridden by area
$text-color-lighter: #403836;
$black: #000000;
$danger-bg: #ffe2ec;
$gray: #acacac;
$gray-2: #a6b8c2;
$gray-navbar-active: #e9e8e7;
$gray-progressbar-bg: #e1e1e1;
$gray-shadow: #b7b7b7;
$green-progressbar: #009468;
$success-bg: #ccffbd;
$white: #ffffff;

// Brands
$brand-primary: $brand-1; // bootstrap var

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/"; // bootstrap var
$font-family-base: "Innogy", sans-serif; // bootstrap var

$font-size-smallest-default: 12px;
$font-size-smaller-default: 14px;
$font-size-small-default: 16px;
$font-size-base-default: 18px;
$font-size-large-default: 24px;
$font-size-larger-default: 30px;
$font-size-largest-default: 60px;
$font-size-huge-default: 80px;

$font-size-base: $font-size-base-default; // bootstrap var
$font-size-small: $font-size-small-default; // bootstrap var
$font-size-large: $font-size-large-default; // bootstrap var

$font-weight-light-default: 300;
$font-weight-regular-default: 400;
$font-weight-medium-default: 500;
$font-weight-bold-default: 700;
$headings-font-weight: $font-weight-medium-default; // bootstrap var
//$btn-font-weight

$line-height-base-default: 1.6;
$line-height-base: $line-height-base-default; // bootstrap var

// Body
//$body-bg: #f5f8fa;

// Borders
//$laravel-border-color: darken($body-bg, 10%);
//$list-group-border: $laravel-border-color;
//$navbar-default-border: $laravel-border-color;
//$panel-inner-border: $laravel-border-color;

// Navbar - all of these are bootstrap vars
$navbar-default-bg: $white;
$navbar-default-link-active-bg: $gray-navbar-active;
$navbar-height: 80px;
$navbar-default-link-color: $text-color-lighter;
$navbar-default-link-active-color: $brand-1;
$navbar-collapse-max-height: 360px;
$navbar-margin-bottom: 0;

// Footer
$footer-height: 80px;

// States, Alerts - bootstrap variables
$state-success-text: $brand-9;
$state-success-bg: $success-bg;
$state-success-border: $brand-9;

$state-danger-text: $brand-3;
$state-danger-bg: $danger-bg;
$state-danger-border: $brand-3;

$alert-border-radius: 0;

//// Buttons
//$btn-font-weight: $font-weight-regular;
//$btn-border-radius-base: 3px;
//
//$btn-default-color: $text-white;
//$btn-default-bg: $orange;
//$btn-default-border: $orange;
//
//$btn-primary-color: $text-white;
//$btn-primary-border: $blue;
//
// Inputs
$input-border: $gray-2;
$input-border-focus: $brand-1;
$input-color: $text-color-default;
$input-color-placeholder: lighten($text-color-default, 30%);
$input-border-radius: 0;
//a6b8c2

// Panels
$panel-heading-padding: 0;
$panel-default-heading-bg: $white;
$panel-body-padding: 0;
$panel-footer-padding: 0;
$panel-footer-bg: $white;
$panel-default-border: none;

// Progressbar
$progress-border-radius: 14px;
$progress-bg: $gray-progressbar-bg;
$progress-bar-success-bg: $green-progressbar;
//#60bb46;

// Thumbnail
$thumbnail-border: none;
$thumbnail-border-radius: 0;

// Loader (custom component)
$loader-color: $brand-1;
$loader-second-color: lighten($loader-color, 10%);
$loader-overlay-color: transparent;

:root {
    --plyr-color-main: #f15922;
}

.user-progress {
    padding: 15px 15px 35px 15px;
    background-color: $white;
    @include box-shadow(0px 2px 0px 0px #b7b7b7, 0px 2px 30px 0px rgba(0, 0, 0, 0.45));
    border: none;
    text-align: left;

    .metric-row {
        padding-left: 15px;
        padding-right: 15px;

        .metric {
            border: 1px solid #e5e5e5;
            width: 100%;
            min-height: 100px;
            padding-top: 10px;
            padding-bottom: 0px;
            margin: 0 8px;
            @include respond-to(screen-md){ margin: 0 2px; }

            .img-col {
                padding-left: 4px;
                padding-right: 10px;
            }

            .metric-title {
                text-transform: uppercase;
                padding-top: 12px;
            }

            .metric-value {
                font-weight: bold;
            }

            .leaderboard-link-wrapper {
                font-weight: normal;
            }

            .battles-won { color: $brand-9; }
            .battles-lost { color: $brand-3; }
            .battles-tie { color: $brand-6; }
        }

        .progress-level {
        }
    }

    .progressbar-section {
        .progressbar-title {
            text-align: center;
            font-weight: $font-weight-medium-default;
            padding: 25px 0px 8px 0px;
        }
        
        .level-names {
            @include font-styles(#707070, $font-size-small-default, $font-weight-medium-default);
            padding-left: 25px;
            padding-right: 25px;

            .level-next { text-align: right; }
        }
    }
}
#profile {
    background-color: $brand-8;

    .part-1 {
        padding: 60px 20px 50px 20px;
        background-color: $brand-8;

        h1, h2 {
            text-align: center;
        }

        h1 {
            color: $brand-6;
            margin-bottom: 10px;
        }

        h2 {
            color: $brand-1;
        }
    }

    .area-specific-part {
        padding: 0px 20px 40px 20px;

        .area-description-row {
            position: relative;

            .bg-img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50%;
                @include respond-to(screen-below-sm) { display: none; }
            }

            .area-description-col {
                padding: 40px 0 30px 0;
                @include respond-to(screen-below-sm) { text-align: center; }

                h2 {
                    text-transform: uppercase;
                }

                p {
                    padding: 0px 0 30px 0;
                    color: $white;
                }

                .area-finished-text {
                    @include font-styles($white, $font-size-smaller-default, $font-weight-light-default);
                    padding-top: 5px;
                }
            }
        }
    }

    .part-2 {
        padding: 40px 20px;
        background-color: $brand-8;

        h2 {
            color: $brand-1;
            text-align: center;
            padding-bottom: 40px;
        }

        .submit-btn-wrapper {
            text-align: center;
            margin-top: 30px;
        }
    }
}
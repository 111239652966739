#not-found {
    background-color: $brand-2;

    .part-1 {
        padding: 60px 20px 50px 20px;
        background-color: $brand-2;
        text-align: center;

        h1 {
            color: $white;
            line-height: 1.1;
        }

        p {
            color: $white;
            padding: 50px;
        }
    }
}
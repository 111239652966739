@font-face {
    font-family: "Innogy";
    src: url('/frontend/assets/fonts/Innogy/Innogy-Light.woff2') format('woff2'),
    url('/frontend/assets/fonts/Innogy/Innogy-Light.woff') format('woff'),
    url('/frontend/assets/fonts/Innogy/Innogy-Light.eot'),
    url('/frontend/assets/fonts/Innogy/Innogy-Light.eot?#iefix') format('embedded-opentype'),
    url('/frontend/assets/fonts/Innogy/Innogy-Light.ttf') format('truetype');
    // svg fonts are too big
    //url('/frontend/assets/fonts/Innogy/Innogy-Light.svg#innogylight') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Innogy";
    src: url('/frontend/assets/fonts/Innogy/Innogy-Regular.woff2') format('woff2'),
    url('/frontend/assets/fonts/Innogy/Innogy-Regular.woff') format('woff'),
    url('/frontend/assets/fonts/Innogy/Innogy-Regular.eot'),
    url('/frontend/assets/fonts/Innogy/Innogy-Regular.eot?#iefix') format('embedded-opentype'),
    url('/frontend/assets/fonts/Innogy/Innogy-Regular.ttf') format('truetype');
    // svg fonts are too big
    //url('/frontend/assets/fonts/Innogy/Innogy-Regular.svg#innogyregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Innogy";
    src: url('/frontend/assets/fonts/Innogy/Innogy-Medium.woff2') format('woff2'),
    url('/frontend/assets/fonts/Innogy/Innogy-Medium.woff') format('woff'),
    url('/frontend/assets/fonts/Innogy/Innogy-Medium.eot'),
    url('/frontend/assets/fonts/Innogy/Innogy-Medium.eot?#iefix') format('embedded-opentype'),
    url('/frontend/assets/fonts/Innogy/Innogy-Medium.ttf') format('truetype');
    // svg fonts are too big
    //url('/frontend/assets/fonts/Innogy/Innogy-Medium.svg#innogymedium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Innogy";
    src: url('/frontend/assets/fonts/Innogy/Innogy-Bold.woff2') format('woff2'),
    url('/frontend/assets/fonts/Innogy/Innogy-Bold.woff') format('woff'),
    url('/frontend/assets/fonts/Innogy/Innogy-Bold.eot'),
    url('/frontend/assets/fonts/Innogy/Innogy-Bold.eot?#iefix') format('embedded-opentype'),
    url('/frontend/assets/fonts/Innogy/Innogy-Bold.ttf') format('truetype');
    // svg fonts are too big
    //url('/frontend/assets/fonts/Innogy/Innogy-Bold.svg#innogybold') format('svg');
    font-weight: 700;
    font-style: normal;
}
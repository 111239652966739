#battles {
    //overflow-y: hidden;

    .part-1 {
        padding: 0px 20px;

        .main-title-row {
            position: relative;
            min-height: 500px;

            .bg-img {
                position: absolute;
                @include respond-to(screen-below-sm) { display: none; }
            }

            .main-title-col {
                padding: 80px 0 30px 0;
                @include respond-to(screen-below-sm) { text-align: center; }

                h1 { line-height: 1.1; }

                .main-subtitle {
                    padding-top: 5px;
                    line-height: 2;
                }
            }
        }
    }

    .part-2 {
        padding: 50px 20px 60px 20px;
        text-align: center;

        .instruction {
            padding-top: 10px;
        }
    }

    .part-3 {
        padding: 50px 20px;
        text-align: center;
    }

    .part-4 {
        padding: 50px 20px;
        text-align: center;
    }

    #no-battle {
        padding: 150px 0 180px 0;

        p {
            font-weight: $font-weight-bold-default;
            padding: 10px 0 15px 0;
        }

        #search-wrapper {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            input[type="text"] {
                border: none;
                border-radius: 25px;
                height: 46px;
                padding-left: 25px;
                padding-right: 200px;
                @include respond-to(screen-below-sm) {
                    padding-right: 25px;
                }
            }

            input[type="text"]:focus {
                outline: none;
            }

            .autocomplete-wrapper {
                .autocomplete {
                    ul {
                        max-height: 210px !important;
                        overflow-y: scroll;
                        left: 30px;
                        width: 100%;
                        max-width: 440px;

                        li {
                            text-align: left;
                            .autocomplete-anchor-text {}
                            .autocomplete-anchor-label { display: none; }
                        }
                    }
                }
            }
            .cta-btn {
                position: absolute;
                right: 0;
                top: 0;
                padding-left: 25px;
                padding-right: 25px;
                @include respond-to(screen-below-sm) {
                    position: static;
                    margin-top: 10px;
                }

                &.btn.disabled, &.btn[disabled] {
                    opacity: 0.9;
                }
            }
        }
    }

    #waiting-for-opponent {
        padding: 130px 0 150px 0;
        h2 { margin-bottom: 15px; }
    }

    #waiting-for-user {
        padding: 100px 0 130px 0;
        p {
            padding: 15px 0 20px 0;
        }
        .secondary-btn {
            margin-left: 10px;
        }
    }

    #battle-in-progress {
        .name-user {
            text-align: left;
            @include respond-to(screen-below-sm) { text-align: center; }
        }
        .name-opponent {
            text-align: right;
            @include respond-to(screen-below-sm) { text-align: center; }
        }

        #battle-progress {
            margin: 30px 0 20px 0;
            padding: 15px;
            box-shadow: 0 2px 30px rgba(0, 0, 0, 0.45), 0 2px 0 $gray-shadow;
            min-height: 129px;

            .question-progress-wrapper {
                display: inline-block;
                vertical-align: top;

                h5 {
                    @include font-styles($brand-1, $font-size-smaller-default, $font-weight-bold-default, 1.2);
                    margin: 0;
                }

                .question-progress-images {
                    padding-top: 10px;

                    .question-user,
                    .question-opponent {
                        width: 25%;
                        display: inline-block;
                        margin-bottom: 12px;
                    }
                    .question-user .bg-img { margin-left: auto; }
                    .question-opponent .bg-img { margin-right: auto; }

                    .question-result {
                        width: 33%;
                        display: inline-block;
                    }
                }

                p {
                    @include font-styles($text-color-default, $font-size-smaller-default, $font-weight-light-default, 1);
                }
            }
        }

        #battle-question {
            .item-view-wrapper {
                .panel-body {
                    border: 2px solid $brand-1;
                    padding: 20px 20px 30px 20px;

                    h4 { color: $text-color-default; }
                    h4.answer-instruction { color: $brand-1; }

                    h3 {
                        @include font-styles($brand-1, $font-size-large-default, $font-weight-medium-default);
                    }
                    p {
                        font-weight: $font-weight-medium-default;
                        margin: 20px 120px;
                        @include respond-to(screen-below-md) { margin: 20px 30px; }
                    }

                    .tag-col { text-align: left; }
                    .validation-message { color: $text-color-default; }
                    .answer-btn { margin-top: 20px; }
                    .item-answer {
                        //padding: 0 50px;
                    }
                }
            }
        }

        #battle-waiting-for-finish {
            padding: 50px 0;
            h2 { margin-bottom: 15px; }
        }
    }
}
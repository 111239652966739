.area-underground {
    $area-path: "areas/underground/";

    // all these variables are local, they do not override any global variables defined in
    // variables.scss
    $color-1: #00aaad;  // subheadings, secondary buttons
    $color-2: #ffc20e;  // headings etc
    $color-3: #007476;  // onboarding part 1, profile area-specific-part
    $color-4: #a8a68c;  // item-dashboard part 2, leaderboard part 1
    $color-5: #534741;  // onboarding part 3, achievements part 2
    $color-6: #403836;  // onboarding part 2, onboarding part 5, focus part 2, navbar bg
    $color-7: #c8c5a4;  // item-dashboard part 1
    $color-8: #dcd9bd;  // flavour text bubble
    $color-9: #20252b;  // onboarding part 4
    $color-10: #009368; // onboarding part 4 headings, user points
    $color-11: #322c2b; // focus part 1, navbar active bg, achievements part 1
    $btn-color: #f15922;

    $color-text: $text-color-default; // NOT the bootstrap var!

    $black: #000000;
    $white: #ffffff;
    $gray-footer: #e2e2e2;

    $font-size-smallest: 12px;
    $font-size-smaller: 14px;
    $font-size-small: 16px;
    $font-size-base: 18px;
    $font-size-large: 24px;
    $font-size-larger: 30px;
    $font-size-largest: 60px;
    $font-size-huge: 80px;

    $font-weight-light: $font-weight-light-default;
    $font-weight-regular: $font-weight-regular-default;
    $font-weight-medium: $font-weight-medium-default;
    $font-weight-bold: $font-weight-bold-default;

    // ----------------
    //  General styles
    // ----------------
    @include general-text($color-text, $font-size-base);
    @include h1($color-2, $font-size-largest, $line-height: 1);
    @include h2($color-1, $font-size-larger);
    @include h3($color-1, $font-size-large);
    @include h4($color-text, $font-size-base, $line-height: 1);
    @include table(#f1f1f1, none);

    .primary-btn {
        @include button-variant($white, $btn-color, $btn-color);
        @include button-size(13px, 51px, $font-size-base, 1, 25px);
        font-weight: $font-weight-medium;
    }

    .secondary-btn {
        @include button-variant($white, $color-1, $color-1);
        @include button-size(13px, 51px, $font-size-base, 1, 25px);
        font-weight: $font-weight-medium;
    }

    .empty-achievements {
        text-align: center;
        color: $white;
    }

    &.ifo-modal {
        .inner-content {
            border: 2px solid $color-1;
        }

        &.achievements {
            @include bg-img($area-path, "progress_achievements.png", 64px) {
                margin: 10px 0;
            };
        }

        &.battles {
            .inner-content { border: none; }
            padding-bottom: 40px !important;

            // because images have no margins
            .bg-img {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            h3 { @include font-styles($text-color-default, $font-size-base, $font-weight-bold); }
            .opponent-name { color: $btn-color; }

            .secondary-btn {
                @include button-variant($white, #37474f, #37474f);
            }

            &.battles-challenged,
            &.battles-opponent-accepted {
                @include bg-img($area-path, "battles_challenged.png", 171px);
            }
            &.battles-opponent-declined {
                @include bg-img($area-path, "battles_opponent_declined.png", 200px);
            }
            &.battles-won {
                @include bg-img($area-path, "battles_won.png", 200px);
                background-color: $brand-9 !important;
                h2 { color: #ffcc80; }
                h3 { color: $white; }
                p { color: $white; }
                .primary-btn {
                    @include button-variant($brand-9, $white, $white);
                }
            }
            &.battles-lost {
                @include bg-img($area-path, "battles_lost.png", 200px);
                background-color: #e53935 !important;
                h2 { color: #ffcc80; }
                h3 { color: $white; }
                p { color: $white; }
                .primary-btn {
                    @include button-variant(#e53935, $white, $white);
                }
            }
            &.battles-tie {
                @include bg-img($area-path, "battles_tie.png", 200px);
                background-color: #f89c1c !important;
                h2 { color: $white; }
                h3 { color: $white; }
                p { color: $white; }
                .primary-btn {
                    @include button-variant(#e53935, $white, $white);
                }
            }
        }

        &.knowledge-test {
            &.knowledge-test-generic {}
            &.knowledge-test-timeout {}
        }
    }

    // ----------------
    //  Pages
    // ----------------

    #onboarding {
        background-color: $color-6;

        .part-1 {
            background-color: $color-3;

            .main-title-row {
                min-height: 600px;

                // @note: height is 546 but the image is too big then, interfering with text
                @include bg-img($area-path, "onboarding_main.png", 450px) {
                    left: 0;
                    bottom: 10%;
                    width: 55%;
                }

                .main-title-col {
                    .main-subtitle {
                        color: $white;
                    }
                }
            }
        }

        .part-2 {
            background-color: $color-6;

            .flavour-text-wrapper {
                background-color: $color-8;
                position: relative;

                @include bg-img($area-path, "onboarding_flavour_leg.png", 75px) {
                    position: absolute;
                    width: 135px;
                    top: 22px;
                    right: -134px;
                    @include respond-to(screen-below-sm) { display: none; }
                }
            }

            .flavour-img-wrapper {
                @include bg-img($area-path, "onboarding_flavour.png", 200px);
            }
        }

        .part-3 {
            background-color: $color-5;

            h1 {
                color: $color-2;
            }

            .first-instruction {
                @include bg-img($area-path, "onboarding_instructions_first.png", 341px);
            }

            .second-instruction {
                @include bg-img($area-path, "onboarding_instructions_second.png", 341px);
            }

            .third-instruction {
                @include bg-img($area-path, "onboarding_instructions_third.png", 341px);
            }
        }

        .part-4 {
            background-color: $color-9;

            h2 {
                color: $color-2;
            }

            h3 {
                color: $color-10;
            }

            p {
                color: $white;
            }
        }

        .part-5 {
            background-color: $color-6;
            padding-top: 30px;
            padding-bottom: 30px;

            @include bg-img($area-path, "onboarding_ctoa_illustration.png", 300px) {
                .cta-btn-wrapper {
                    bottom: 39%;
                    left: 42%;

                    // this is specific for Fantasy/Silkroad/Underground
                    @include respond-to(screen-below-sm) {
                        left: 30% !important;
                        bottom: 42%;
                    }
                }
            };
        }
    }

    #item-dashboard {
        background-color: $color-4;

        .part-1 {
            background-color: $color-7;

            .item-view-row {

                .item-view-wrapper {
                    .panel-body {
                        @include bg-img($area-path, "item_default.png", 190px);

                        .answer-response-view {
                            &.correct-answer {
                                @include bg-img($area-path, "item_correct_answer.png", 190px);
                            }

                            &.incorrect-answer {
                                @include bg-img($area-path, "item_incorrect_answer.png", 190px);
                            }
                        }

                        .no-items-view {
                            @include bg-img($area-path, "item_clean_table.png", 190px);
                        }
                    }
                }

                .explanation-col {
                    p {
                        color: $text-color-default;
                    }
                }
            }
        }

        .part-2 {
            background-color: $color-4;

            h2 {
                color: $color-2;
            }
        }
    }

    #focus {
        background-color: $color-6;

        .part-1 {
            background-color: $color-11;
        }

        .part-2 {
            background-color: $color-6;

            h1 {
                color: $color-2;
            }

            h2 {
                color: $color-1;
            }

            h3 {
                color: $white;
            }

            .categories-row {
                .category-box {

                    .content-wrapper {
                        h3 {
                            color: $color-1;
                        }
                    }

                    &.no-focus {
                        // @todo: the right ratio if it changes
                        @include responsive-bg-img($area-path, "focus_none.png", 0.54054054, $black);
                    }
                }
            }
        }
    }

    #achievements {
        background-color: $color-5;

        .part-1 {
            background-color: $color-11;
        }

        .part-2 {
            background-color: $color-5;
        }
    }

    #leaderboard {
        background-color: $color-4;

        .part-1 {
            background-color: $color-4;

            .rank-section {
                @include bg-img($area-path, "leaderboard_rank.png", 64px);
            }
        }
    }

    .navbar {
        @include box-shadow(none);
        background-color: $color-6;

        .navbar-nav > li {
            > a {
                color: $white;
                font-weight: $font-weight-regular-default;
                background-color: $color-6;
                cursor: pointer;
            }

            &.user-info {
                a {
                    color: $white;
                }

                .user-points {
                    color: $color-10;
                }

                &.active{
                    background-color: $color-11;

                    a {
                        color: $color-2;
                    }
                    .user-points {
                        color: $color-10;
                    }
                }

                // this is specific for Fantasy/Silkroad/Underground
                .user-info-content-wrapper {
                    border-color: $white;
                }
            }
        }

        .navbar-nav > .active > a,
        .navbar-nav > .active > a:hover,
        .navbar-nav > .active > a:focus {
            background-color: $color-11;
            color: $color-2;
        }
    }

    #battles {
        background-color: #652E50;
        h2 { @include font-styles(#ffcc80, $font-size-larger, $font-weight-bold, 1.2); }
        h4 { @include font-styles(#ffcc80, $font-size-base, $font-weight-bold, 1.6); }

        .part-1 {
            background-color: #ffcc80;

            .main-title-row {
                min-height: 550px;

                @include bg-img($area-path, "battles_main.png", 550px) {
                    left: 0;
                    bottom: 0;
                    max-width: 608px;
                    width: 55%;
                    @include respond-to(screen-sm) { width: 46%; }
                }

                .main-title-col {
                    h1 {
                        color: #662D51;
                    }
                    .main-subtitle {
                        color: $black;
                    }
                }
            }
        }

        .part-2 {
            background-color: #37474F;

            .instruction {
                h3 {
                    font-size: 20px;
                    font-weight: $font-weight-medium-default;
                }
                p { font-size: 16px; }
                .bg-img {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            .first-instruction {
                @include bg-img($area-path, "battles_instructions_first.png", 280px);
            }

            .second-instruction {
                @include bg-img($area-path, "battles_instructions_second.png", 280px);
            }

            .third-instruction {
                @include bg-img($area-path, "battles_instructions_third.png", 280px);
            }
        }

        .part-3 {
            background-color: #843A5F;
        }

        .part-4 {
            background-color: #652E50;
        }

        #no-battle {
            @include bg-img($area-path, "battles_no_battle.png", 200px) {
                margin-bottom: 20px;
            };

            @include placeholder-styles(#fefefe);

            input[type="text"] {
                background-color: #4a1c39;
                color: $white;
            }

            p { color: $white; }

            .autocomplete-wrapper {
                .autocomplete ul,
                .autocomplete ul li a {
                    background-color: #4a1c39;
                    color: $white;
                }
            }
        }

        #waiting-for-user {
            @include bg-img($area-path, "battles_challenged.png", 171px);
            p { color: $white; }

            .secondary-btn {
                @include button-variant($white, #37474F, #37474F);
            }
        }

        #waiting-for-opponent,
        #battle-waiting-for-finish {
            @include bg-img($area-path, "battles_waiting.png", 200px);
            p { color: $white; }
        }

        #battle-in-progress {
            color: $white;

            #header-row {
                p { color: $white; }
            }

            #battle-progress {
                background-color: $white;

                .question-progress-wrapper {
                    p { color : #231f20; }

                    // @note: this pictures should ideally be square
                    .question-correct {
                        @include bg-img($area-path, "battles_question_correct.png", 32px) { max-width: 32px; };
                    }
                    .question-incorrect {
                        @include bg-img($area-path, "battles_question_incorrect.png", 32px) { max-width: 32px; };
                    }
                    .question-unknown {
                        @include bg-img($area-path, "battles_question_unknown.png", 32px) { max-width: 32px; };
                    }
                    // @note: it's best for these pictures to be exactly 50px high
                    .question-both-correct { @include bg-img($area-path, "battles_question_both_correct.png", 50px) }
                    .question-both-incorrect { @include bg-img($area-path, "battles_question_both_incorrect.png", 50px); }
                    .question-won { @include bg-img($area-path, "battles_question_won.png", 50px); }
                    .question-lost { @include bg-img($area-path, "battles_question_lost.png", 50px); }
                    .question-wait { @include bg-img($area-path, "battles_question_wait.png", 50px); }
                }
            }
        }
    }

    .knowledge-test-box {
        @include responsive-bg-img($area-path, "focus_knowledge_test.png", 0.54054054, $black);
    }

    #knowledge-test {
        background-color: #243a56;
        .part-1 { background-color: #243a56; }
    }
}

// this must be outside of area class parent - because it is also used in Profile for each area
#profile {
    .area-specific-part.part-underground {
        background-color: #007476;

        .area-description-row {
            min-height: 310px; // @note: this is set to the height of the image + padding from top
            @include bg-img("areas/underground/", "profile_area_specific.png", 300px);

            .area-description-col {
                h2 {
                    color: #ffc20e;
                }

                p, .area-finished-text {
                    color: $white;
                }
            }
        }
    }
}

.area-underground .user-progress,
.area-specific-part.part-underground .user-progress {
    .metric-row {
        .metric {
            padding-top: 17px;
            .metric-title {
                padding-top: 5px;
            }
        }

    }
    .progress-level {
        .bg-img {
            // image url is set from code as it changes w.r.t. level
            height: 64px;
        }
    }

    .progress-items {
        @include bg-img("areas/underground/", "progress_items.png", 64px);
    }

    .progress-rank {
        @include bg-img("areas/underground/", "progress_rank.png", 64px);
    }

    .progress-achievements {
        @include bg-img("areas/underground/", "progress_achievements.png", 64px);
    }

    .progress-battles {
        @include bg-img("areas/underground/", "progress_battles.png", 64px);
    }
}

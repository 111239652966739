#reset-password {
    background-color: $brand-2;

    .part-1 {
        padding: 60px 20px 50px 20px;
        background-color: $brand-2;
        text-align: center;

        h1, h2 {
            color: $white;
            line-height: 1.1;
            padding-bottom: 20px;
        }

        h3 {
            color: $brand-6;
            line-height: 1.1;
            padding-bottom: 20px;
        }

        .panel {
            margin-top: 30px;

            .btn-row {
                text-align: center;
            }
        }
    }
}